@import 'varijable-i-mixini';

/* reset browser styles */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
    line-height: 1.2;
}
ol, ul {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
} 
/* end reset browser styles */


// ------Općeniti stilovi------
* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

body {
    background: #fff;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    color: $font-color;
    overflow-x: hidden;
    padding-top: 120px;
}

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.wrapper-small {
    max-width: 760px;
    margin: 0 auto;
}

h1, h2, h3, h4 {
    color: $heading-color;
    font-weight: 700;
    line-height: 1;
    padding: 0 20px;
}

h1, h2 {
    text-align: center;
}

h1 {
    color: #fff;
    font-size: 2.5em;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 0 20px; 
}

h2 {
    font-size: 2.25em;
    margin: 60px 0 40px 0;
}

h3 {
    font-size: 1.3125em;
}

h4 {
    font-size: 1em;
}

p {
    line-height: 1.6;
    padding: 0 20px;
}

// ------Navigacija i logo-------

.header-s-navigacijom {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    background: #fff;
    border-bottom: 1px solid $ngit-crvena;
}

.navbar {
    height: 120px;
    @include display(flex);
    @include flexFlow(wrap, row);
    @include flexJustifyContent(space-between);
    @include flexAlignItems(center);
}

.nav-logo-i-hamburger {
    padding: 0 20px;
}

.nav-menu {
    margin-right: 20px;
}

.logo {
    display: block;
    width: 156px;
    height: 65px;
    background: url("img/logo.png") no-repeat;
}

.nav-icon {
    display: none;
}

.nav-linkovi {
    @include display(flex);
    @include flexFlow(wrap, row);
    text-transform: uppercase;
    margin-top: 7px;
}

.nav-linkovi li +  li {
    padding-left: 30px;
}

.nav-linkovi a {
    text-transform: uppercase;
    font-size: 1.125em;
    font-weight: 800;
    line-height: 1;
    color: $heading-color;
    text-decoration: none;
}

.nav-linkovi a:after {
    content: "";
    display: block;
    width: 0;
    height: 7px;
    background: $ngit-crvena;
    @include prefix(transition, width .3s, webkit o);
}

.nav-linkovi a:hover:after {
    width: 100%; 
}

// ---------Header------------
#particles-js {
    background-color: $ngit-crvena;
    height: 400px;
    position: relative;
    // margin-top: 120px;
}

.wrapper-outer {
    position: absolute;
    top: 50%;
    @include prefix(transform, translateY(-50%), webkit ms);
    width: 100%;
}

#particles-js p {
    font-size: 0.875em;
    color: #fff;
    text-align: center;
    padding: 0 20px;
    letter-spacing: 0.6px; 
}

// -----------O nama-------------
.onama p {
    font-size: 1.125em;
    text-align: center;
}

// ------------Kartice-----------
.kartice {
    padding: 0 10px;
}
// border-radius i sjena na svim karticama
.kartica-inner { 
    border-radius: 5px;
    @include prefix(box-shadow, 0px 0px 10px 0px rgba(0, 0, 0, 0.1), webkit);
    margin-bottom: 25px;
    position: relative;
    opacity: 0;
}

.kartica-inner.in-view {
    @include prefix(animation, animationFadeUp .5s ease-in-out .25s forwards, webkit);
}

@-webkit-keyframes animationFadeUp {
    from {
        @include prefix(transform, translateY(30px), webkit);
        opacity: 0;
    }
    to {
        @include prefix(transform, translateY(0), webkit);
        opacity: 1;
    }
}

@keyframes animationFadeUp {
    from {
        @include prefix(transform, translateY(30px), webkit);
        opacity: 0;
    }
    to {
        @include prefix(transform, translateY(0), webkit);
        opacity: 1;
    }
}
 
.kartice ul {
    font-size: 0.875em;
    line-height: 1.5;
    position: relative;
}

.kartice li {
    padding-left: 20px;
}
// bulleti na karticama
.kartice li:before {
    content: "\00BB";
    position: absolute;
    left: 0;
}

.backend li:before {
    color: $ngit-crvena;
}

.sistemska-administracija li:before {
    color: $plava;
}

.web-sustav li:before {
    color: $zelena;
}

.mobilne-aplikacije li:before {
    color: $zuta;
}

.kartice h3 {
    padding: 30px 10px 20px 0;
}

.backend h3 {
    color: $ngit-crvena;
}

.sistemska-administracija h3 {
    color: $plava;
}

.web-sustav h3 {
    color: $zelena;
}

.mobilne-aplikacije h3 {
    color: $zuta;
}

.backend, .sistemska-administracija, .web-sustav, .mobilne-aplikacije {
    padding: 0 20px 30px 120px;
}

.backend:before, .sistemska-administracija:before, .web-sustav:before, .mobilne-aplikacije:before {
    content: "";
    position: absolute;
    left: 20px;
    top:20px;
    width: 74px;
    height: 74px;  
}

.backend:before {
    background: url("img/icon-laptop.svg") no-repeat;
}

.sistemska-administracija:before {
    background: url("img/icon-server.svg") no-repeat;
}

.web-sustav:before {
    background: url("img/icon-globe.svg") no-repeat;
}

.mobilne-aplikacije:before {
    background: url("img/icon-mobile.svg") no-repeat;
}

// -------Karijera-----------
.karijera h3 {
    margin: 40px 0;
}

h4 span {
    float: right;
}

//------Graf----------

.graf_item {
    padding: 0 20px 30px 20px;
}

.graf_item h4 {
    font-size: 0.875em; 
    padding: 0;
    margin-bottom: 20px;
}

.graf_bar {
    position: relative;
    width: 100%;
    height: 20px;
    background-color: #ebebeb;
    border-radius: 50px;
    @include prefix(box-shadow, inset 0 0 9px rgba(0,0,0,.1), webkit);
}

.graf_bar_inner_one, .graf_bar_inner_two, .graf_bar_inner_three  {
    position: absolute;
    height: 20px;
    background-color: #1ca5be;
    border-radius: 50px;
    top: 0;
    left: 0;
}

.graf_bar_inner_one.in-view {
    @include prefix(animation, postotak-99 2s forwards, webkit);
    
} 

@-webkit-keyframes postotak-99 {
    from { right:100%; }
    to { right:1%; }
} 

@keyframes postotak-99 {
    from { right:100%; }
    to { right:1%; }
}



.graf_bar_inner_two.in-view {
    @include prefix(animation, postotak-30 2s forwards, webkit);
}

@-webkit-keyframes postotak-30 {
    from { right:100%; }
    to { right:70%; }
}

@keyframes postotak-30 {
    from { right:100%; }
    to { right:70%; }
}

.graf_bar_inner_three.in-view {
    @include prefix(animation, postotak-70 2s forwards, webkit);
}

@-webkit-keyframes postotak-70 {
    from { right:100%; }
    to { right:30%; }
}

@keyframes postotak-70 {
    from { right:100%; }
    to { right:30%; }
}

// ---------Galerija-------------

.galerija-grid img {
    display: block;
    width: 100%;
    height: 100%;
    @include prefix(object-fit, cover, o);
}

// varijanta bez grida s floatom i bez dvije okomite slike zbog prikaza na IE koji ne podržava grid
.galerija-grid {
    overflow: hidden;
}

.galerija-grid figure {
    width: 25%;
    float: left;
    position: relative;
}

.galerija-grid figure.okomita {
    display: none;
}
// end IE varijanta


//overlay na slike
.galerija-grid figcaption {
    position: absolute;
    background-color: rgba(228, 53, 54, .8);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include display(flex);
    @include flexFlow(nowrap, column);
    @include flexJustifyContent(center);
    @include flexAlignItems(center);
    color: #fff;
    opacity: 0;
    @include prefix(transition, opacity .75s ease-out, webkit o);
    cursor: pointer;
}

.galerija-grid p {
    padding: 0;
    position: relative;
    padding-left: 30px;
    line-height: 20px;
}

.galerija-grid figcaption p:before {
    content: "";
    background: url(img/povecalo.svg) no-repeat 0 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
}

.galerija-grid figure:hover figcaption {
    opacity: 1;
}


// -----------Zaposlenje--------------

.zaposlenje h4 {
    margin: 30px 0;
    color: $font-color;
    line-height: 1.5;
    text-align: center;
}

.zaposlenje h3 {
    font-size: 1.125em;
    font-weight: 600;
    text-align: center;
    color: $zuta;
    padding: 40px 20px 30px 20px;
    line-height: 1.2;
}

.zaposlenje .kartica-inner {
    padding-bottom: 45px;
}

.zaposlenje .kartice p {
    margin-bottom: 10px;
    padding: 0 40px;
}

.zaposlenje .kartice li:before {
    content: "\2713";
}

.zaposlenje ul {
    margin: 0 40px;
}

.zaposlenje a {
    color: $heading-color;
    font-weight: 700;
    text-decoration: none;
    display: inline-block;
}

.zaposlenje a:after {
    content: ""    ;
    display: block;
    width: 0;
    height: 6px;
    background: $ngit-crvena;
    @include prefix(transition, width .3s, webkit o);
    margin-top: -3px;
}

.zaposlenje a:hover:after {
    width: 100%;
}

// ----------Kontakt------------

.contact-sections {
    @include display(flex);
    @include flexFlow(wrap, column);
    @include flexJustifyContent(space-around);
    text-align: center;
}

.contact-sections > section {
    margin-bottom: 40px;
    opacity: 0;
}

.email.in-view {
    @include prefix(animation, animationFadeLeft .5s ease-in-out .25s forwards, webkit);
}

@-webkit-keyframes animationFadeLeft {
    from {
        @include prefix(transform, translateX(-80px), webkit);
        opacity: 0;
    }
    to {
        @include prefix(transform, translateX(0), webkit);
        opacity: 1;
    }
}

@keyframes animationFadeLeft {
    from {
        @include prefix(transform, translateX(-80px), webkit);
        opacity: 0;
    }
    to {
        @include prefix(transform, translateX(0), webkit);
        opacity: 1;
    }
}

.radno-vrijeme.in-view {
    @include prefix(animation, animationFadeUp .5s ease-in-out .25s forwards, webkit);
}

.lokacija.in-view {
    @include prefix(animation, animationFadeRight .5s ease-in-out .25s forwards, webkit);
}

@-webkit-keyframes animationFadeRight {
    from {
        @include prefix(transform, translateX(80px), webkit);
        opacity: 0;
    }
    to {
        @include prefix(transform, translateX(0), webkit);
        opacity: 1;
    }
}

@keyframes animationFadeRight {
    from {
        @include prefix(transform, translateX(80px), webkit);
    }
    to {
        @include prefix(transform, translateX(0), webkit);
        opacity: 1;
    }
}

.envelope, .clock, .location {
    width: 100px;
    height: 100px;
    background: $ngit-crvena url(img/envelope.svg) no-repeat center center;
    border-radius: 50%;
    margin: 0 auto 20px auto;
}

.envelope {
    background: $ngit-crvena url(img/envelope.svg) no-repeat center center;
}

.clock {
    background: $zuta url(img/clock.svg) no-repeat center center;
}

.location {
    background: $zelena url(img/location.svg) no-repeat center center;
}

.kontakt h4 {
    color: $font-color;
}

// ----------Karta-------------
#map {
    height: 400px;
    width: 100%;
}

#map h3 {
    line-height: 1.5em;
}

// --------Footer------------

footer {
    background-color: $ngit-crvena;
    text-align: center;
    color: #fff;
    padding: 30px 0 20px 0;
}

.footer-nav {
    margin-bottom: 30px;
}

.footer-nav li + li {
    padding-top: 8px;
}

.footer-nav a {
    color: #fff;
    text-decoration: none;
    line-height: 1;
    display: inline-block;
}

.footer-nav a:after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #fff;
    -webkit-transition: width .3s;
    -o-transition: width .3s;
    transition: width .3s;

}

.footer-nav a:hover:after {
    width: 100%;
}

#top {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: -60px;
    cursor: pointer;
    -webkit-transition: bottom .2s;
    -o-transition: bottom .2s;
    transition: bottom .2s;
}

#top.vidljiv {
    bottom: 10px;
}
.lokacija-ureda p {
    margin-bottom: 1em;
    margin-top: 1em;
}


// -------Media queriji-----------


@media screen and (min-width: 1210px) {
    .nav-logo-i-hamburger, .kartice {
        padding: 0;
    }
    .nav-menu {
        margin-right: 0
    }
    
}

@media screen and (min-width: 1150px) {
    
    @supports ((display: -ms-grid) or (display: grid)) {
        footer {
            @include display(grid);
            -ms-grid-columns: 1fr 2fr 1fr;
                grid-template-columns: 1fr 2fr 1fr;
            padding-bottom: 30px;
        }
        
        .footer-nav {
            @include order(2);
            @include display(flex);
            @include flexFlow(wrap, row);
            @include flexJustifyContent(center);
            margin: 0;
            line-height: 1;
        }
        
        .footer-nav li + li {
            padding-top: 0;
            padding-left: 20px;
        }
        
        .copyright {
            @include order(1);
            text-align: left;
            line-height: 1;
        }
    }    
}

@media screen and (min-width: 700px) {
    
    h1 {
        font-size: 3.875em;
    }

    h2 {
        font-size: 3em;
    }

    #particles-js p {
        font-size: 1.125em;
    }

    .kontakt h2 {
        margin-bottom: 50px;
    }

    .contact-sections {
        @include flexDirection(row);
        text-align: center;
    }

    @supports ((display: -ms-grid) or (display: grid)) {
        
        .kartice {
            grid-gap: 34px;
        }
        
        .cime-se-bavimo .kartice {
            @include display(grid);
            -ms-grid-rows: (1fr)[2];
                grid-template-rows: repeat(2, 1fr);
            -ms-grid-columns: (1fr)[2];
                grid-template-columns: repeat(2, 1fr);
        }


        // kad ima više natječaja
        /*.zaposlenje .kartice {
            @include display(grid);
            -ms-grid-columns: (minmax(320px, 1fr))[auto-fit];
                grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
            margin-bottom: 30px;
        }*/
        // više natječaja end

        // kad ima jedan natječaj
        .zaposlenje .kartice {
            @include display(grid);
            grid-template-columns: 1fr 760px 1fr;
            margin-bottom: 30px;
        }

        .zaposlenje .kartica-inner:first-child {
            grid-column: 2/3;
        }
        //jedan natječaj end

        .kartica-inner  {
            margin-bottom: 0;
        }

        

    // -------Galerija start
    
        .galerija-grid figure {//gazi stilove koji su stavljeni zbog IE koji ne podržava grid
            width: 100%;
            float: none;
        }

        .galerija-grid {
            @include display(grid);
            -ms-grid-columns: (minmax(200px, 1fr))[auto-fit];
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));               
        }
    
        .galerija-grid figure {
            grid-column: span 2;
            grid-row: span 2;
        } 
    
        .galerija-grid figure.okomita {
            display: block; 
            grid-column: span 1;
            grid-row: span 2;
        }

        .galerija-grid figure.velika {
            grid-column: span 4;
            grid-row: span 4;
        }

    }

    .top:hover circle {
        fill: $ngit-crvena;
        stroke: #fff;
    }
    
    .top:hover path {
        fill: #fff;
    }
}

@media screen and (min-width: 960px) and (max-width:1200px) {
    .galerija-grid {
        -ms-grid-columns: (minmax(160px, 1fr))[auto-fit];
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));                           
    }
}

@media screen and (min-width: 700px) and (max-width:960px) {
    .galerija-grid {
        -ms-grid-columns: (minmax(116px, 1fr))[auto-fit];
            grid-template-columns: repeat(auto-fit, minmax(116px, 1fr));                           
    }
}

@media screen and (min-width: 700px) and (max-width:1200px) {
    .kartice {
        grid-gap: 10px; 
    }
}

@media screen and (min-width: 1000px) and (max-width:1200px) {
    .nav-linkovi li + li {
        padding-left: 20px;
    }
}


//------ Navigacija za manje uređaje --------
@media screen and (max-width: 999px) {

    body {
        padding-top: 60px; // navbar je sada nešto niži
    }
    
    .navbar {
        height: 60px;
        position: relative;
    }

    .nav-logo-i-hamburger {
        @include display(flex);
        @include flexFlow(wrap, row);
        @include flexJustifyContent(space-between);
        @include flexAlignItems(center);
        background: #fff;
        width: 100%;
        height: 100%;
    }
    
    .nav-menu {
        position: absolute;
        top: -310px;
        left: 0;
        background-color: rgba(255, 255, 255, 1);
        width: 100%;
        padding: 30px 0;
        margin-right: 0;
        border-bottom: 1px solid $ngit-crvena; 
        z-index: -100;  
    }

    .nav-menu.open {
        top: 61px;
    }

    .nav-menu.tranzicija {
        @include prefix(transition, all 0.3s, webkit o);
    }

    //hamburger ikonica start
    .nav-icon {
        display: block;
        position: relative;
        width: 40px;
        height: 30px;
        cursor: pointer;    
    }

    .nav-icon .icon-bar {
        width: 100%;
        height: 6px;
        background-color: $heading-color;
        position: absolute;
        opacity: 1;
        left: 0;
        @include prefix(transform, rotate(0deg), webkit ms);
        @include prefix(transition, .25s ease-in-out, webkit o);
    }
    
    .nav-icon .icon-bar:nth-child(1) {
        top: 0;
        @include prefix(transform-origin, left center, webkit ms);
    }

    .nav-icon .icon-bar:nth-child(2) {
        top: 12px;
        @include prefix(transform-origin, left center, webkit ms);
    }

    .nav-icon .icon-bar:nth-child(3) {
        top: 24px;
        @include prefix(transform-origin, left center, webkit ms);
    }

    .nav-icon.iks .icon-bar:nth-child(1) {
        @include prefix(transform, rotate(45deg), webkit ms);
        top: -2px;
    }

    .nav-icon.iks .icon-bar:nth-child(2) {
        width: 0;
        opacity: 0;
    }

    .nav-icon.iks .icon-bar:nth-child(3) {
        @include prefix(transform, rotate(-45deg), webkit ms);
        top: 26px;
    }


    //hamburger ikonica end

    .nav-linkovi {
        @include flexFlow(wrap, column);
        margin-top: 0;
        text-align: center;
    }

    .nav-linkovi li +  li {
        padding-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .nav-linkovi li:first-child {
        padding-bottom: 10px;
    }

    .nav-linkovi li:last-child {
        padding-bottom: 0;
    }

    .nav-linkovi a {
        display: inline-block;
    }

    .nav-linkovi a:after {
        @include prefix(transition, none, webkit o);
    }
    
    .nav-linkovi a:hover:after {
        width: 0; 
    }

    .logo {
        display: block;
        width: 95px;
        height: 40px;
        background: url("img/logo-manji.png") no-repeat;
    }

    .lokacija-ureda--img img {
        width: 100%;
    }
}



//------ Galerija za male ekrane ---------
@media screen and (max-width: 699px) {

    @supports ((display: -ms-grid) or (display: grid)) {
        .galerija-grid figure {//gazi stilove koji su stavljeni zbog IE koji ne podržava grid
            width: 100%;
            float: none;
        }
        
        .galerija-grid {
            @include display(grid);
            grid-gap: 2px;
            -ms-grid-columns: (1fr)[4];
                grid-template-columns: repeat(4, 1fr);
            -ms-grid-rows: (1fr)[4];
                grid-template-rows: repeat(4, 1fr);
        }
    
        .galerija-grid figure {
            grid-column: span 2;
            grid-row: span 2;
        }
    
        .galerija-grid figure.okomita {
            display: block; 
            grid-column: span 1;
            grid-row: span 2;
        }

        .galerija-grid figure:first-child {
            grid-column: span 4;
            grid-row: span 4;
            padding-top: 1px;    
        }
    }    
}

//------ Ikone na karticama za manje ekrane--------

@media screen and (max-width: 768px) {
    .backend, .sistemska-administracija, .web-sustav, .mobilne-aplikacije {
        padding: 20px 20px 40px 20px;
    }

    .backend:before, .sistemska-administracija:before, .web-sustav:before, .mobilne-aplikacije:before {
        content: "";
        margin: 0 auto;
        position: static;
        display: block;
        width: 74px;
        height: 74px; 
    }

    .kartice h3 {
        text-align: center;
    }
}