// Varijable 

$ngit-crvena: #e32525;
$plava: #1ca5be;
$zuta: #f4ca05;
$zelena: #93bf5a;

$font-color: #6b6b6b;
$heading-color: #494949;

/// Mixin to prefix a property
/// @author Hugo Giraudel
/// @param {String} $property - Property name
/// @param {*} $value - Property value
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
}

@mixin flexAlignItems($value){
    @if $value == "center" {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    } @else if $value == "baseline" {
        -webkit-box-align: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    } @else if $value == "stretch" {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    } @else if $value == "flex-start" {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    } @else if $value == "flex-end" {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    
}

@mixin flexJustifyContent($value){
    @if $value == "flex-start" {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    } @else if $value == "flex-end" {
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    } @else if $value == "center" {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    } @else if $value == "space-between" {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    } @else if $value == "space-around" {
        -ms-flex-pack: distribute;
        justify-content: space-around;
    } @else if $value == "space-evenly" {
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
         justify-content: space-evenly;
    }     
}


@mixin flexFlow($wrap, $layout){
    @if $layout == "column" or $layout == "column-reverse" {
        -webkit-box-orient: vertical;
    } @else if $layout == "row" or $layout == "row-reverse" {
        -webkit-box-orient: horizontal;
    } 
    
    @if $layout == "row" or $layout == "column" {
        -webkit-box-direction: normal;
    } @else if $layout == "row-reverse" or $layout == "column-reverse" {
        -webkit-box-direction: reverse;
    }
    @include prefix(flex-flow, $wrap $layout, ms);
}

@mixin flexDirection($layout) {
    @if $layout == "column" or $layout == "column-reverse" {
        -webkit-box-orient: vertical;
    } @else if $layout == "row" or $layout == "row-reverse" {
        -webkit-box-orient: horizontal;
    } 
    
    @if $layout == "row" or $layout == "column" {
        -webkit-box-direction: normal;
    } @else if $layout == "row-reverse" or $layout == "column-reverse" {
        -webkit-box-direction: reverse;
    }

    @include prefix(flex-direction, $layout, ms);    
}


@mixin display($display) {
    @if $display == "flex" {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    } @else if $display == "grid" {
        display: -ms-grid;
        display: grid;
    }
}

@mixin order($num) {
    -webkit-box-ordinal-group: $num + 1;
    -ms-flex-order: $num;
        order: $num;
}